/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import './layout.scss';
import Theme from './common/Theme';

const GlobalStyle = createGlobalStyle`

  video, image {
    display: block;
  }

  h1, .finalText {
    font-family: "Oranienbaum", sans-serif;
  }

  h3 {
    font-family: "Mark Pro", sans-serif;
    font-weight: 100;
    color: ${(props) => props.theme.colors.grey};
  }

  h2, .arrowText p {
    font-family: "Nothing You Could Do", serif;
    color: ${(props) => props.theme.colors.darkGreen};
    font-weight: 600;
    line-height: 1.4;
  }
  
  h2 {
    font-size: ${(props) => props.theme.fontSizes.l};
  }

  .arrowText p {
    font-size: ${(props) => props.theme.fontSizes.m};
    margin: 0;
    white-space: nowrap;
  }

  p {
    font-family: "Mark Pro", sans-serif;
    font-weight: 300;
    line-height: 2;
    color: ${(props) => props.theme.colors.black};
    font-size: ${(props) => props.theme.fontSizes.s};
  }

  h4 {
    font-family: "Mark Pro", sans-serif;
    font-weight: 400;
    color: ${(props) => props.theme.colors.teal};
    text-transform: uppercase;
    font-size: ${(props) => props.theme.fontSizes.xs};
    letter-spacing: 0.1rem;
  }
`;

const Layout = ({ children }) => (
  <>
    <Theme>
      <GlobalStyle />
      <main>{children}</main>
    </Theme>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    black: '#282828',
    grey: '#818181',
    teal: '#8FAAA4',
    darkGreen: '#134451',
  },
  fonts: ['Oranienbaum', 'Mark Pro', 'Nothing You Could Do'],
  fontSizes: {
    xs: '0.7rem',
    s: '0.8rem',
    m: '1rem',
    l: '1.4rem',
    xl: '4.5rem',
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
